import styled from 'styled-components'
import ClipLoader from "react-spinners/ClipLoader"


export const FormInput = styled.input`
    width: ${({wt}) => wt? wt:'80%'};
    height: 3.5rem;
    padding: 3%;
    margin-bottom: 1rem;
    border-radius: 3px;
    border: ${({ warn }) => warn? "1px solid red" : "1px solid black"}; 
`

export const FormButton = styled.button`
    background: rgba(17, 115, 171, 1);
    color: white;
    padding: 4%;
    width: 80%;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    &:hover {
        background: rgb(10 101 153);
    }
`
export const FormText = styled.p`
    color: rgba(17, 115, 171, 1);
    text-align: center;
    width: 80%;
`

export const FormLogo = styled.img`
    height: 4.5rem;
    width: 70%;
`
export const FormIcon = styled.img`
    position: absolute;
    z-index: 1;
    right: 0;
    margin-top: 1rem;
    margin-right: 1rem;
    height: 20px;
    cursor: pointer;
`
export const Form = styled.form`
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
`
export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10%;
    width: 40%;
    height: 400px;
    margin: auto;
    transform: translate(13%, 30%);
`
export const FormFailed = styled.p`
    color: red;
    position: relative;
    bottom: ${({ sin }) => sin? "2rem":"4rem"};
    align-self: flex-end;
`

export const FormMini = styled.div`
    position: relative;
    height: 40px;
    display: flex;
    width: 80%;
    margin-bottom: 4rem;
`
export const Spinner = styled(ClipLoader)`
    align-self: center;
    justify-self: center;
`