import React from 'react'
import { BackDrop, HeaderWrapper, ModalWrapper } from '../style'
import CheckedForm from '../../form/checks'

const PopUpCheck = () => {
  return (
    <>
      <BackDrop></BackDrop>
      <ModalWrapper ht={'9rem'}>
          <CheckedForm />
      </ModalWrapper>
    </>
  )
}

export default PopUpCheck