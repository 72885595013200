import styled from 'styled-components'


export const ChartDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3%;
    height: 379px;
    flex-wrap: wrap;
    background: #fbf9f9; //#F6EDED
    width: 32%;
    align-content: center;
`

export const ChartHeader = styled.h4`
    color: black;
    font-weight: 700;
    text-align: center;
`

