import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'



const initialState = {

    loading: false,
    user: [],
    error: '',
    err: null,
    loggedIn: false
}


export const signinData  = createAsyncThunk('user/signinData', (objdata) => {
        const {url, loginInfo, config} = objdata;
        return axios
              .post( url, loginInfo,
                {
                  withCredentials: true,
                  headers: config
                }
              )
              .then(response => response.data)
              .catch((error) => {
                throw error.response; // Throw the entire response object
              });
            })  
const signinSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      cook: (state, action) => {
        state.user =  action.payload
        state.loggedIn = true
      },
      refresh: (state, action) => {
        state.user.tokens.access = action.payload.access
        
        state.user.tokens.refresh = action.payload.refresh
        
      },
    },
    extraReducers: builder => {
          builder.addCase(signinData.pending, state => {
            state.loading = true
            state.error=""
            state.err=""
          })
          builder.addCase(signinData.fulfilled, (state, action) => {
            state.loading = false
            state.user = action.payload
            state.error = ''
            if(state.user != null){
              state.loggedIn = true
            }else state.loggedIn = false
          })
          builder.addCase(signinData.rejected, (state, action) => {
            state.loading = false
            state.user = []
            state.err= action.payload.status
            state.error = action.error.message
          });
         
    }
});


export default signinSlice.reducer
export const { cook, refresh } = signinSlice.actions