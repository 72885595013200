import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'



const initialState = {

    loading: false,
    data: [],
    error: '',
    status: '',
    success: false,
}


export const putData  = createAsyncThunk('data/putData', async (objdata, { rejectWithValue }) => {
  const {url, data, config} = objdata;
  try {
    const result = await axios.put(url, data, {headers: config})
    return result.data
  }catch(err){
    return rejectWithValue({status: err.response?.status, data: err.response?.data, text: err.message})
  }
})
const putSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
    clearp: (state, action) => {
      state.success = false
    },
    errorClear: (state, action) => {
        state.error = ""
    },
    successClear: (state, action) => {
      state.success = false
    }
    },
    extraReducers: builder => {
          builder.addCase(putData.pending, state => {
            state.loading = true
            state.error= ''
            state.success = false
          })
          builder.addCase(putData.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = ''
            state.success = true
          })
          builder.addCase(putData.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.payload.data
            state.status = action.payload.status
            state.success = false
          });
    },
});


export default putSlice.reducer
export const { clearp, errorClear, successClear } = putSlice.actions