import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store} from './app/store';
import { persistStore } from 'redux-persist';
import ErrorBoundary from './components/errors/boundary';


const root = ReactDOM.createRoot(document.getElementById('root'));
// const persistors = persistStore(store);
// persistors.purge().then(() => console.log('cleared'))
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>  
    </ErrorBoundary>
  </React.StrictMode>
);

