import styled, {keyframes} from 'styled-components'
import { NavLink as Link } from 'react-router-dom'


const open = keyframes`
    from {
        opacity: 0;
        position: relative;
        top: -130vh;
    }
    to {
        opacity: 1;
        position: relative;
        top: 0;
    }
`

export const MenuWrapper = styled.div`
    width: 18%;
    background: rgba(17, 115, 171, 1);
    display: flex;
    flex-direction: column;
    gap: 3%;
    padding: 5.05% 0 0 3%;
    animation: ${open} 1s ease;
    /* height: 100vh; */
    height: auto;
    /* position: fixed;
    left: 0; */
`

export const MenuLink = styled(Link)`
    width: 100%;
    display: flex;
    text-decoration: none;
    color: white;
    background: rgba(17, 115, 171, 1);
    padding: 4% 5% 5% 1%;
    cursor: pointer;

    &.active {
        color: black;
        background: white;
    }
    .unclicked {
        color: white;
        background: rgba(17, 115, 171, 1);
    }
`
export const MenuIcon = styled.img`
    height: 25px;
    margin-right: 10%;
    margin-left: 15%;
`

export const MenuText = styled.p`
    padding: 0;
    margin: 0;
    margin-top: 4%;
`