import { useDispatch, useSelector } from "react-redux";
import { set_id } from "../../features/singleSlice/single";
import { ListContainer, ListItem, ListMini, ListProfile, ListSpan, ListText, ListWrapper, Text } from './style'
import HeaderVerify from '../../layouts/header'
import ExButton from '../button'
import ddown from '../../assets/icons/doubledown.svg'
import cross from '../../assets/icons/cross.svg'
import checked from '../../assets/icons/checked.svg'
import Tag from '../tags'
import { useNavigate } from "react-router-dom";
import { postData } from "../../features/postSlice/post";
import { ShortenStr } from "../../utility";


const ListBusiness = ({data, show, top}) => {
    const user = useSelector((state) => state.signin.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const config = {
        "Content-Type": "application/json", 
        "Authorization": `JWT ${user?.tokens?.access}`
    }
    function retrieve_id(id, role){
        dispatch(set_id({user_id: id, role: role}))
        navigate('/verification')
    } 
    function refute(id, role){
        const url2 = `${process.env.REACT_APP_BASE_APP_URL}/role/refute/${role}/${id}/`
        dispatch(postData({url: url2, config: config}));
    }
  return (
    <ListContainer top={top}>
        <HeaderVerify color={'black'} text={'Business Verification'} sicon={ddown} sicon_alt={'more'} />
        <Tag text='All' mg={'2% 0 2% 8%'}/>
        <ListWrapper>
            {data.length > 0?
                <>
                    {data.map((item, index) => (
                        <ListItem key={index}>
                            <ListMini top>
                                <ListProfile src={item?.profile_photo} />
                                <ListText color='rgba(12, 140, 233, 1)' onClick={() => retrieve_id(item.id, item.role)}>
                                    {ShortenStr(item.email === "doesn't have any"? item?.user?.email : item?.email, 18)}
                                    <ListSpan>
                                        just
                                    </ListSpan>     
                                    Verified KYC 1
                                </ListText>
                            </ListMini>
                            <ListMini>
                                <ExButton text='Refute Documents' term={'Refute Documents'} func={() => refute(item.id, item.role)} icon={cross} iconalt={'refute documents'}/>
                                <ExButton text='Verify Documents' icon={checked} iconalt={'verify documents'} func={() => retrieve_id(item.id, item.role)}/>
                            </ListMini>
                        </ListItem>
                    ))}
                </>
                :
                <>
                    <Text>No unverified businesses at this time</Text>
                </>
            }
        </ListWrapper>
        {show? <Tag text={'View fullscreen'} color={'rgba(0, 0, 0, 0.69)'} mg={'auto'} hide />:<></>}
    </ListContainer>
  )
}

export default ListBusiness;